import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { ToastIconContainer } from './components';

class ToastService {
  public show(node: ReactNode, options?: ToastOptions): void {
    toast(node, {
      ...options,
      icon: () => (options?.type ? ToastIconContainer({ type: options.type }) : undefined)
    });
  }
}

export const toastService = new ToastService();
